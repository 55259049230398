<template>
  <div class="page container p-4">
    <div class="columns">
      <div class="column is-6 is-offset-3">
        <div class="has-text-centered">
          <img
            class="anon-avatar"
            :src="currentUserAvatar"
            alt="identifícate"
          >
        </div>
        <h1 class="has-text-centered has-text-weight-medium is-size-3">
          Asociar códigos
        </h1>
        <b-field message="Puedes introducir varios códigos separándolos por espacios.">
          <b-input
            ref="codes"
            v-model="form.codes"
            type="text"
            placeholder="Código"
            required
            maxlength="200"
            @blur="validateForm"
          />
        </b-field>
        <b-button
          class="mb-3"
          type="is-primary"
          expanded
          @click="addCode"
        >
          ASOCIAR
        </b-button>
        <b-button
          class="mb-3"
          type="is-secondary"
          expanded
          @click="goBack"
        >
          VOLVER
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import md5 from "md5"

export default {
  title: 'Asociar código',
  name: 'AssociateCode',
  components: {},
  data: function () {
    return {
      isValid: false,
      gravatarUrl: 'https://s.gravatar.com/avatar/{{emailmd5}}?s=86',
      form: {
        codes: '',
      }
    }
  },
  computed: {
    profile() {
      return this.$store.state.user
    },
    currentUserAvatar() {
      if (!this.profile) {
        return "@/assets/img/anon-avatar.png"
      }

      const md5email = md5(this.profile.email)

      return this.gravatarUrl.replace('{{emailmd5}}', md5email)
    }
  },
  mounted() {
    if (this.isAuth) {
      this.resetForm()
    }
  },
  methods: {
    async addCode() {
      this.validateForm()

      if (this.isValid) {

        try {
          await this.$store.dispatch('initLoading')

          const codes = this.form.codes.split(" ")

          const response = await this.$api.user.associateCodes(codes)

          await this.$store.dispatch('finishLoading')

          let importResultMsg = '';

          if (response.not_found.count > 0) {
            importResultMsg += '<b>No encontrados:</b> ' + response.not_found.list.join(', ')
          }

          if (response.used.count > 0) {
            importResultMsg += '<b>Ignorados:</b> ' + response.used.list.join(', ')
          }

          if (response.forbidden.count > 0) {
            importResultMsg += '<b>No permitidos:</b> ' + response.forbidden.list.join(', ')
          }

          if (response.success.count > 0) {
            importResultMsg += '<b>Asociados:</b> ' + response.success.list.join(', ')
          }

          this.$buefy.dialog.confirm({
            title: 'Asociación de códigos',
            message: importResultMsg,
            cancelText: 'Cerrar',
            confirmText: 'Perfil',
            onConfirm: () => this.goToProfile()
          })

          //this.resetForm()
        } catch (error) {
          await this.$store.dispatch('finishLoading')
          const data = error.response.data
          this.$msg.error(data.message)
        }
      }
    },
    validateForm() {
      const validateFields = ["codes"];
      let isValidFormData = true

      validateFields.forEach((item) => {
        if (this.$refs.hasOwnProperty(item)) {
          const itemIsValid = this.$refs[item].checkHtml5Validity()
          isValidFormData = isValidFormData && itemIsValid
        }
      })

      this.isValid = isValidFormData
    },
    resetForm() {
      this.form.code = ''
    },
    goBack() {
      this.$router.push('profile')
    },
    goToProfile() {
      this.$router.push('profile')
    }
  }
}
</script>

<style type="scss" scoped>
</style>
